// C9 CONSTANTS
export const CURRENT_QUEUE_SETTING = "CURRENT_QUEUE_SETTING";
export const TARGETS_DATA = "TARGETS_DATA";
export const CURRENT_TARGET = "CURRENT_TARGET";
export const CURRENT_USER = "CURRENT_USER";
export const PALETTES = "PALETTES"
export const CURRENT_ISSUE = "CURRENT_ISSUE"
export const CURRENT_AUTH_METHOD = "CURRENT_AUTH_METHOD";
export const CURRENT_NOTIFICATIONS_1 = "CURRENT_NOTIFICATIONS_1";
export const CURRENT_NOTIFICATIONS_2 = "CURRENT_NOTIFICATIONS_2";
export const LOGGED_IN = "LOGGED_IN";
export const GRID_SELECTED_INDEX = "GRID_SELECTED_INDEX";
export const CURRENT_SCHEDULE_EVENTS = "CURRENT_SCHEDULE_EVENTS";
export const AD_HOC_TO_SHOW = "AD_HOC_TO_SHOW";
export const REFRESH_NOTIFICATION_PAGE = "REFRESH_NOTIFICATION_PAGE";
//export const RECENT_SCAN_PAGE = "RECENT_SCAN_PAGE";

// SETTINGS CONSTANTS
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = "FETCH_CHAT_SUCCESS";
export const SELECTED_CHAT = "SELECTED_CHAT";
export const SEARCH_USER = "SEARCH_USER";
export const MSG_SUBMIT = "MSG_SUBMIT";

// TODO CONSTANTS
export const FETCH_TODO_REQUEST = "FETCH_TODO_REQUEST";
export const FETCH_TODO_SUCCESS = "FETCH_TODO_SUCCESS";
export const FETCH_TODO_FAILURE = "FETCH_TODO_FAILURE";
export const TOGGLE_STARRED_TODO = "TOGGLE_STARRED_TODO";
export const SET_VISIBILITY_FILTER_TODO = "SET_VISIBILITY_FILTER_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const TODO_DETAILS = "TODO_DETAILS";
export const FILTER_TODO = "FILTER_TODO";
export const COMPLETE_TODO = "COMPLETE_TODO";
export const UPDATE_TODO = "UPDATE_TODO";
export const ADD_TODO = "ADD_TODO";

// CONTACT CONSTANTS
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
export const FILTER_CONTACT = "FILTER_CONTACT";
export const TOGGLE_STARRED_CONTACT = "TOGGLE_STARRED_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

// EMAIL CONSTANTS
export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";

// NOTES CONSTANTS

export const SELECTED_NOTES = "SELECTED_NOTES";
export const SEARCH_NOTES = "SEARCH_NOTES";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const ADD_NOTE = "ADD_NOTE";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";


export const ActionTypeList = {
    /********************* Current Login ****************/
    CURRENT_LOGIN_INCREMENT_SUBSCRIPTION_PLAN_CHOOSER_PAGE_VISI_COUNT: 'CURRENT_LOGIN_INCREMENT_SUBSCRIPTION_PLAN_CHOOSER_PAGE_VISI_COUNT',

    /********************* User Setting ****************/
    USER_SETTING_FETCH_LIST: 'USER_SETTING_FETCH_LIST',
    USER_SETTING_LIST_FETCHED: 'USER_SETTING_LIST_FETCHED',

    USER_SETTING_UPDATE_EDIT_MODE: 'USER_SETTING_UPDATE_EDIT_MODE',
    USER_SETTING_EDIT_MODE_UPDATED: 'USER_SETTING_EDIT_MODE_UPDATED',

    USER_SETTING_TOGGLE_NEW_FLAG: 'USER_SETTING_TOGGLE_NEW_FLAG',
    USER_SETTING_NEW_FLAG_TOGGLED: 'USER_SETTING_NEW_FLAG_TOGGLED',

    USER_SETTING_FETCH_DETAIL: 'USER_SETTING_FETCH_DETAIL',
    USER_SETTING_DETAIL_FETCHED: 'USER_SETTING_DETAIL_FETCHED',

    USER_SETTING_NEW_DETAIL: 'USER_SETTING_NEW_DETAIL',
    USER_SETTING_DETAIL_NEWED: 'USER_SETTING_DETAIL_NEWED',

    USER_SETTING_UPDATE: 'USER_SETTING_UPDATE',
    USER_SETTING_UPDATED: 'USER_SETTING_UPDATED',
    USER_SETTING_UPDATED_HIDE_MSG: 'USER_SETTING_UPDATED_HIDE_MSG',
    USER_SETTING_INSERT: 'USER_SETTING_INSERT',
    USER_SETTING_INSERTED: 'USER_SETTING_INSERTED',
    USER_SETTING_INSERTED_HIDE_MSG: 'USER_SETTING_INSERTED_HIDE_MSG',
    USER_SETTING_DELETE: 'USER_SETTING_DELETE',
    USER_SETTING_DELETED: 'USER_SETTING_DELETED',
    USER_SETTING_DELETED_HIDE_MSG: 'USER_SETTING_DELETED_HIDE_MSG',
    USER_SETTING_UPLOAD_PROFILE_PICTURE: 'USER_SETTING_UPLOAD_PROFILE_PICTURE',
    USER_SETTING_PROFILE_PICTURE_UPLOADED: 'USER_SETTING_PROFILE_PICTURE_UPLOADED',
    USER_SETTING_RESET_USER_DETAIL_INPUT_FIELDS: 'USER_SETTING_RESET_USER_DETAIL_INPUT_FIELDS',

    USER_SETTING_UPDATE_FIELD: 'USER_SETTING_UPDATE_FIELD',
    USER_SETTING_FIELD_UPDATED: 'USER_SETTING_FIELD_UPDATED',

    USER_SETTING_UPDATE_CURRENT_USER_ID: 'USER_SETTING_UPDATE_CURRENT_USER_ID',
    USER_SETTING_CURRENT_USER_ID_UPDATED: 'USER_SETTING_CURRENT_USER_ID_UPDATED',

    USER_SETTING_UPDATE_DETAIL_PAGE_MODE: 'USER_SETTING_UPDATE_DETAIL_PAGE_MODE',
    USER_SETTING_DETAIL_PAGE_MODE_UPDATED: 'USER_SETTING_DETAIL_PAGE_MODE_UPDATED',

    USER_SETTING_LOAD_RESET_PASSWORD_PAGE: 'USER_SETTING_LOAD_RESET_PASSWORD_PAGE',
    USER_SETTING_RESET_PASSWORD_PAGE_LOADED: 'USER_SETTING_RESET_PASSWORD_PAGE_LOADED',
    USER_SETTING_UNLOAD_RESET_PASSWORD_PAGE: 'USER_SETTING_UNLOAD_RESET_PASSWORD_PAGE',
    USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED: 'USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED',

    USER_SETTING_SEND_INVITATION_STATE_RESET: 'USER_SETTING_SEND_INVITATION_STATE_RESET',
    USER_SETTING_SEND_INVITATION: 'USER_SETTING_SEND_INVITATION',
    USER_SETTING_INVITATION_SENT: 'USER_SETTING_INVITATION_SENT',

    /********************* Target Setting ****************/
    TARGET_SETTING_FETCH_LIST: 'TARGET_SETTING_FETCH_LIST',
    TARGET_SETTING_LIST_FETCHED: 'TARGET_SETTING_LIST_FETCHED',

    ACCOUNT_TARGET_SETTING_FETCH_LIST: 'ACCOUNT_TARGET_SETTING_FETCH_LIST',
    ACCOUNT_TARGET_SETTING_LIST_FETCHED: 'ACCOUNT_TARGET_SETTING_LIST_FETCHED',

    TARGET_SETTING_UPDATE_EDIT_MODE: 'TARGET_SETTING_UPDATE_EDIT_MODE',
    TARGET_SETTING_EDIT_MODE_UPDATED: 'TARGET_SETTING_EDIT_MODE_UPDATED',

    TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE: 'TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE',
    TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED: 'TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED',

    TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE: 'TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE',
    TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED: 'TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED',

    TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE: 'TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE',
    TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED: 'TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED',

    TARGET_SETTING_BASIC_AUTH_UPDATE_EDIT_MODE: 'TARGET_SETTING_BASIC_AUTH_UPDATE_EDIT_MODE',
    TARGET_SETTING_BASIC_AUTH_EDIT_MODE_UPDATED: 'TARGET_SETTING_BASIC_AUTH_EDIT_MODE_UPDATED',

    TARGET_SETTING_FORM_AUTH_UPDATE_EDIT_MODE: 'TARGET_SETTING_FORM_AUTH_UPDATE_EDIT_MODE',
    TARGET_SETTING_FORM_AUTH_EDIT_MODE_UPDATED: 'TARGET_SETTING_FORM_AUTH_EDIT_MODE_UPDATED',

    TARGET_SETTING_JWT_AUTH_UPDATE_EDIT_MODE: 'TARGET_SETTING_JWT_AUTH_UPDATE_EDIT_MODE',
    TARGET_SETTING_JWT_AUTH_EDIT_MODE_UPDATED: 'TARGET_SETTING_JWT_AUTH_EDIT_MODE_UPDATED',

    TARGET_SETTING_HTTP_NTLM_AUTH_UPDATE_EDIT_MODE: 'TARGET_SETTING_HTTP_NTLM_AUTH_UPDATE_EDIT_MODE',
    TARGET_SETTING_HTTP_NTLM_AUTH_EDIT_MODE_UPDATED: 'TARGET_SETTING_HTTP_NTLM_AUTH_EDIT_MODE_UPDATED',

    TARGET_SETTING_TOGGLE_NEW_FLAG: 'TARGET_SETTING_TOGGLE_NEW_FLAG',
    TARGET_SETTING_NEW_FLAG_TOGGLED: 'TARGET_SETTING_NEW_FLAG_TOGGLED',

    TARGET_SETTING_FETCH_DETAIL: 'TARGET_SETTING_FETCH_DETAIL',
    TARGET_SETTING_DETAIL_FETCHED: 'TARGET_SETTING_DETAIL_FETCHED',

    ACCOUNT_TARGET_SETTING_UPDATE_CURRENT_DETAIL: 'ACCOUNT_TARGET_SETTING_UPDATE_CURRENT_DETAIL',
    ACCOUNT_TARGET_SETTING_CURRENT_DETAIL_UPDATED: 'ACCOUNT_TARGET_SETTING_CURRENT_DETAIL_UPDATED',

    TARGET_SETTING_NEW_DETAIL: 'TARGET_SETTING_NEW_DETAIL',
    TARGET_SETTING_DETAIL_NEWED: 'TARGET_SETTING_DETAIL_NEWED',

    TARGET_SETTING_UPDATE: 'TARGET_SETTING_UPDATE',
    TARGET_SETTING_UPDATED: 'TARGET_SETTING_UPDATED',
    TARGET_SETTING_INSERT: 'TARGET_SETTING_INSERT',
    TARGET_SETTING_INSERTED: 'TARGET_SETTING_INSERTED',
    TARGET_SETTING_DELETE: 'TARGET_SETTING_DELETE',
    TARGET_SETTING_DELETED: 'TARGET_SETTING_DELETED',
    TARGET_SETTING_DEACTIVATE: 'TARGET_SETTING_DEACTIVATE',
    TARGET_SETTING_DEACTIVATED: 'TARGET_SETTING_DEACTIVATED',
    TARGET_SETTING_ACTIVATE: 'TARGET_SETTING_ACTIVATE',
    TARGET_SETTING_ACTIVATED: 'TARGET_SETTING_ACTIVATED',

    TARGET_SETTING_INSERTED_HIDE_MSG: 'TARGET_SETTING_INSERTED_HIDE_MSG',
    TARGET_SETTING_DEACTIVATED_HIDE_MSG: 'TARGET_SETTING_DEACTIVATED_HIDE_MSG',
    TARGET_SETTING_ACTIVATED_HIDE_MSG: 'TARGET_SETTING_ACTIVATED_HIDE_MSG',

    TARGET_SETTING_UPDATE_FIELD: 'TARGET_SETTING_UPDATE_FIELD',
    TARGET_SETTING_FIELD_UPDATED: 'TARGET_SETTING_FIELD_UPDATED',

    TARGET_SETTING_UPDATE_CURRENT_TARGET_ID: 'TARGET_SETTING_UPDATE_CURRENT_TARGET_ID',
    TARGET_SETTING_CURRENT_TARGET_ID_UPDATED: 'TARGET_SETTING_CURRENT_TARGET_ID_UPDATED',

    TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE: 'TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE',
    TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED: 'TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED',

    ACCOUNT_TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE: 'ACCOUNT_TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE',
    ACCOUNT_TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED: 'ACCOUNT_TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED',

    TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST: 'TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST',
    TARGET_SETTING_AUTHENTICATION_TEST_SUBMITTED: 'TARGET_SETTING_AUTHENTICATION_TEST_SUBMITTED',

    ACCOUNT_TARGET_SETTING_INITIATE_DEACTIVATION_MODE: 'ACCOUNT_TARGET_SETTING_INITIATE_DEACTIVATION_MODE',
    ACCOUNT_TARGET_SETTING_DEACTIVATION_MODE_INITIATED: 'ACCOUNT_TARGET_SETTING_DEACTIVATION_MODE_INITIATED',

    ACCOUNT_TARGET_SETTING_INITIATE_ACTIVATION_MODE: 'ACCOUNT_TARGET_SETTING_INITIATE_ACTIVATION_MODE',
    ACCOUNT_TARGET_SETTING_ACTIVATION_MODE_INITIATED: 'ACCOUNT_TARGET_SETTING_ACTIVATION_MODE_INITIATED',

    TARGET_SETTING_GET_AUTHENTICATION_TEST_RESULT: 'TARGET_SETTING_GET_AUTHENTICATION_TEST_RESULT',
    TARGET_SETTING_AUTHENTICATION_TEST_RESULT_GOTTEN: 'TARGET_SETTING_AUTHENTICATION_TEST_RESULT_GOTTEN',

    TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST_CANCELLATION: 'TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST_CANCELLATION',
    TARGET_SETTING_AUTHENTICATION_TEST_CANCELLATION_SUBMITTED: 'TARGET_SETTING_AUTHENTICATION_TEST_CANCELLATION_SUBMITTED',

    TARGET_SETTING_RESET_AUTHENTICATION_TEST_RESULT: 'TARGET_SETTING_RESET_AUTHENTICATION_TEST_RESULT',
    TARGET_SETTING_AUTHENTICATION_TEST_RESULT_RESET: 'TARGET_SETTING_AUTHENTICATION_TEST_RESULT_RESET',

    TARGET_SETTING_UPDATE_SELECTED_AUTH_TAB_INDEX: 'TARGET_SETTING_UPDATE_SELECTED_AUTH_TAB_INDEX',
    TARGET_SETTING_SELECTED_AUTH_TAB_INDEX_UPDATED: 'TARGET_SETTING_SELECTED_AUTH_TAB_INDEX_UPDATED',

    TARGET_SETTING_UPDATE_STATUS_RESET: 'TARGET_SETTING_UPDATE_STATUS_RESET',

    /********************* Account Setting ****************/
    ACCOUNT_SETTING_FETCH: 'ACCOUNT_SETTING_FETCH',
    ACCOUNT_SETTING_FETCHED: 'ACCOUNT_SETTING_FETCHED',

    ACCOUNT_SETTING_UPDATE_TIME_ZONE_EDIT_MODE: 'ACCOUNT_SETTING_UPDATE_TIME_ZONE_EDIT_MODE',
    ACCOUNT_SETTING_TIME_ZONE_EDIT_MODE_UPDATED: 'ACCOUNT_SETTING_TIME_ZONE_EDIT_MODE_UPDATED',

    ACCOUNT_SETTING_UPDATE_GENERAL_EDIT_MODE: 'ACCOUNT_SETTING_UPDATE_GENERAL_EDIT_MODE',
    ACCOUNT_SETTING_GENERAL_EDIT_MODE_UPDATED: 'ACCOUNT_SETTING_GENERAL_EDIT_MODE_UPDATED',

    ACCOUNT_SETTING_UPDATE_BILLING_EDIT_MODE: 'ACCOUNT_SETTING_UPDATE_BILLING_EDIT_MODE',
    ACCOUNT_SETTING_BILLING_EDIT_MODE_UPDATED: 'ACCOUNT_SETTING_BILLING_EDIT_MODE_UPDATED',

    ACCOUNT_SETTING_UPDATE_FIELD: 'ACCOUNT_SETTING_UPDATE_FIELD',
    ACCOUNT_SETTING_FIELD_UPDATED: 'ACCOUNT_SETTING_FIELD_UPDATED',

    ACCOUNT_SETTING_UPDATE: 'ACCOUNT_SETTING_UPDATE',
    ACCOUNT_SETTING_UPDATED: 'ACCOUNT_SETTING_UPDATED',
    ACCOUNT_SETTING_UPDATE_STATUS_RESET: 'ACCOUNT_SETTING_UPDATE_STATUS_RESET',

    PUBLIC_PLANS_FETCH: 'PUBLIC_PLANS_FETCH',
    PUBLIC_PLANS_FETCHED: 'PUBLIC_PLANS_FETCHED',

    ACCOUNT_SUBSCRIPTION_CANCEL: 'ACCOUNT_SUBSCRIPTION_CANCEL',
    ACCOUNT_SUBSCRIPTION_CANCELLED: 'ACCOUNT_SUBSCRIPTION_CANCELLED',
    ACCOUNT_SUBSCRIPTION_CANCELLATION_CLOSE_SCREEN: 'ACCOUNT_SUBSCRIPTION_CANCELLATION_CLOSE_SCREEN',
    ACCOUNT_SUBSCRIPTION_CANCELLATION_SHOW_SCREEN: 'ACCOUNT_SUBSCRIPTION_CANCELLATION_SHOW_SCREEN',
    //ACCOUNT_SUBSCRIPTION_CLOSE_SCREEN: 'ACCOUNT_SUBSCRIPTION_CLOSE_SCREEN',
    //ACCOUNT_SUBSCRIPTION_SHOW_SCREEN: 'ACCOUNT_SUBSCRIPTION_SHOW_SCREEN',

    ACCOUNT_SUBSCRIPTION_UPDATE: 'ACCOUNT_SUBSCRIPTION_UPDATE',
    ACCOUNT_SUBSCRIPTION_UPDATED: 'ACCOUNT_SUBSCRIPTION_UPDATED',

    /********************* Dashboard ****************/
    DASHBOARD_DATA_FETCH: 'DASHBOARD_DATA_FETCH',
    DASHBOARD_DATA_FETCHED: 'DASHBOARD_DATA_FETCHED',
    
    /********************* Scannings ****************/
    SCANNINGS_DATA_FETCH: 'SCANNINGS_DATA_FETCH',
    SCANNINGS_DATA_FETCHED: 'SCANNINGS_DATA_FETCHED',
    SCAN_LAUNCH: 'SCAN_LAUNCH',
    SCAN_LAUNCHED: 'SCAN_LAUNCHED',
    SCAN_LAUNCH_STATUS_RESET: 'SCAN_LAUNCH_STATUS_RESET',

    SCHEDULED_SCANS_TO_DB_SAVE: 'SCHEDULED_SCANS_TO_DB_SAVE',
    SCHEDULED_SCANS_TO_DB_SAVED: 'SCHEDULED_SCANS_TO_DB_SAVED',
    SCAN_CANCEL: 'SCAN_CANCEL',
    SCAN_CANCELED: 'SCAN_CANCELED',
    SCAN_CANCEL_DATA_RESET: 'SCAN_CANCEL_DATA_RESET',

    /********************* Findings ****************/
    FINDINGS_DATA_FETCH: 'FINDINGS_DATA_FETCH',
    FINDINGS_DATA_FETCHED: 'FINDINGS_DATA_FETCHED',
    WIKI_DATA_FETCH: 'WIKI_DATA_FETCH',
    WIKI_DATA_FETCHED: 'WIKI_DATA_FETCHED',

    /********************* Issue details ****************/
    ASSIGNEE_UPDATE: 'ASSIGNEE_UPDATE',
    ASSIGNEE_UPDATED: 'ASSIGNEE_UPDATED',
    ASSIGNEE_UPDATE_STATUS_RESET: 'ASSIGNEE_UPDATE_STATUS_RESET',

    FEEDBACK_UPDATE: 'FEEDBACK_UPDATE',
    FEEDBACK_UPDATED: 'FEEDBACK_UPDATED',
    FEEDBACK_UPDATE_STATUS_RESET: 'FEEDBACK_UPDATE_STATUS_RESET',

    /********************* Target details ****************/
    TARGET_DETAILS_DATA_FETCH: 'TARGET_DETAILS_DATA_FETCH',
    TARGET_DETAILS_DATA_FETCHED: 'TARGET_DETAILS_DATA_FETCHED',

    /********************* Settings ****************/
    SETTINGS_DATA_FETCH: 'SETTINGS_DATA_FETCH',
    SETTINGS_DATA_FETCHED: 'SETTINGS_DATA_FETCHED',

    /********************* Authentication ****************/
    AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER: 'AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER',
    AUTHENTICATION_CURRENT_LOGIN_USER_UPDATED: 'AUTHENTICATION_CURRENT_LOGIN_USER_UPDATED',

    LOGIN_USER_GET: 'LOGIN_USER_GET',
    LOGIN_USER_GOTTEN: 'LOGIN_USER_GOTTEN',
    LOGIN_USER_UPDATE_FIELD: 'LOGIN_USER_UPDATE_FIELD',
    LOGIN_USER_FIELD_UPDATED: 'LOGIN_USER_FIELD_UPDATED',
    LOGIN_USER_UPDATE: 'LOGIN_USER_UPDATE',
    LOGIN_USER_UPDATED: 'LOGIN_USER_UPDATED',
    LOGIN_USER_UPDATE_EDIT_MODE: 'USER_SETTING_UPDATE_EDIT_MODE',
    LOGIN_USER_EDIT_MODE_UPDATED: 'USER_SETTING_EDIT_MODE_UPDATED',
    
    /********************* billing ****************/
    BILLING_DATA_FETCH: 'BILLING_DATA_FETCH',
    BILLING_DATA_FETCHED: 'BILLING_DATA_FETCHED',
    
    /********************* notifications ****************/
    NOTIFICATIONS_DATA_FETCH: 'NOTIFICATIONS_DATA_FETCH',
    NOTIFICATIONS_DATA_FETCHED: 'NOTIFICATIONS_DATA_FETCHED',

    NOTIFICATIONS_READ_STATUS_SAVE: "NOTIFICATIONS_READ_STATUS_SAVE",
    NOTIFICATIONS_READ_STATUS_SAVED: "NOTIFICATIONS_READ_STATUS_SAVED",
    NOTIFICATIONS_ACKNOWLEDGE_NEW_NOTIFICATIONS: "NOTIFICATIONS_ACKNOWLEDGE_NEW_NOTIFICATIONS",
    NOTIFICATIONS_NEW_NOTIFICATIONS_ACKNOWLEDGED: "NOTIFICATIONS_NEW_NOTIFICATIONS_ACKNOWLEDGED",

    CLOSE_MESSAGE_BOX: 'CLOSE_MESSAGE_BOX',

    TARGET_DETAIL_SHOW_NEW_SCREEN: 'TARGET_DETAIL_SHOW_NEW_SCREEN',
    TARGET_DETAIL_CLOSE_NEW_SCREEN: 'TARGET_DETAIL_CLOSE_NEW_SCREEN',
    TARGET_DETAIL_PAGE_UPDATE_FIELD: 'TARGET_DETAIL_PAGE_UPDATE_FIELD',

    /********************* Scan queue setting ****************/
    SCAN_QUEUE_SETTING_DATA_FETCH: 'SCAN_QUEUE_SETTING_DATA_FETCH',
    SCAN_QUEUE_SETTING_DATA_FETCHED: 'SCAN_QUEUE_SETTING_DATA_FETCHED',
    SCAN_QUEUE_SETTING_UPDATE: 'SCAN_QUEUE_SETTING_UPDATE',
    SCAN_QUEUE_SETTING_UPDATED: 'SCAN_QUEUE_SETTING_UPDATED',
    SCAN_QUEUE_SETTING_HIDE_MSG: 'SCAN_QUEUE_SETTING_HIDE_MSG',
    SCAN_QUEUE_SETTING_NO_MSG_UPDATE: 'SCAN_QUEUE_SETTING_NO_MSG_UPDATE',
    SCAN_QUEUE_SETTING_NO_MSG_UPDATED: 'SCAN_QUEUE_SETTING_NO_MSG_UPDATED',

    CREATE_STRIPE_CHECKOUT_SESSION_REQUEST: 'CREATE_STRIPE_CHECKOUT_SESSION_REQUEST',
    CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS: 'CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS',

    /********************* Terms of Service ****************/
    TOS_LATEST_FETCH: 'TOS_LATEST_FETCH',
    TOS_LATEST_FETCHED: 'TOS_LATEST_FETCHED',

    TOS_FETCH: 'TOS_FETCH',
    TOS_FETCHED: 'TOS_FETCHED',

    TOS_ACCEPTANCE_SUBMIT: 'TOS_ACCEPTANCE_SUBMIT',
    TOS_ACCEPTANCE_SUBMITTED: 'TOS_ACCEPTANCE_SUBMITTED',

    USER_ACCOUNT_ACTIVATION_FETCH: 'USER_ACCOUNT_ACTIVATION_FETCH',
    USER_ACCOUNT_ACTIVATION_FETCHED: 'USER_ACCOUNT_ACTIVATION_FETCHED',

    LOGIN_PAGE_VISIT: 'LOGIN_PAGE_VISIT',
}

export const ActionTypeEventMappingList = {
    /********************* User Setting ****************/
    USER_SETTING_FETCH_LIST: ActionTypeList.USER_SETTING_LIST_FETCHED,
    USER_SETTING_UPDATE: ActionTypeList.USER_SETTING_UPDATED,
    USER_SETTING_INSERT: ActionTypeList.USER_SETTING_INSERTED,
    USER_SETTING_DELETE: ActionTypeList.USER_SETTING_DELETED,
    USER_SETTING_UPDATE_EDIT_MODE: ActionTypeList.USER_SETTING_EDIT_MODE_UPDATED,
    USER_SETTING_FETCH_DETAIL: ActionTypeList.USER_SETTING_DETAIL_FETCHED,
    USER_SETTING_TOGGLE_NEW_FLAG: ActionTypeList.USER_SETTING_NEW_FLAG_TOGGLED,
    USER_SETTING_UPDATE_FIELD: ActionTypeList.USER_SETTING_FIELD_UPDATED,
    USER_SETTING_UPDATE_CURRENT_USER_ID: ActionTypeList.USER_SETTING_CURRENT_USER_ID_UPDATED,
    USER_SETTING_UPDATE_DETAIL_PAGE_MODE: ActionTypeList.USER_SETTING_DETAIL_PAGE_MODE_UPDATED,
    USER_SETTING_UPLOAD_PROFILE_PICTURE: ActionTypeList.USER_SETTING_PROFILE_PICTURE_UPLOADED,
    USER_SETTING_LOAD_RESET_PASSWORD_PAGE: ActionTypeList.USER_SETTING_RESET_PASSWORD_PAGE_LOADED,
    USER_SETTING_UNLOAD_RESET_PASSWORD_PAGE: ActionTypeList.USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED,
    USER_SETTING_SEND_INVITATION: ActionTypeList.USER_SETTING_INVITATION_SENT,


    /********************* Target Setting ****************/
    TARGET_SETTING_FETCH_LIST: ActionTypeList.TARGET_SETTING_LIST_FETCHED,
    ACCOUNT_TARGET_SETTING_FETCH_LIST: ActionTypeList.ACCOUNT_TARGET_SETTING_LIST_FETCHED,
    TARGET_SETTING_UPDATE: ActionTypeList.TARGET_SETTING_UPDATED,
    TARGET_SETTING_INSERT: ActionTypeList.TARGET_SETTING_INSERTED,
    TARGET_SETTING_DELETE: ActionTypeList.TARGET_SETTING_DELETED,
    TARGET_SETTING_DEACTIVATE: ActionTypeList.TARGET_SETTING_DEACTIVATED,
    TARGET_SETTING_ACTIVATE: ActionTypeList.TARGET_SETTING_ACTIVATED,
    TARGET_SETTING_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_EDIT_MODE_UPDATED,
    TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED,
    TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_RECORD_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED,
    TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_MANUAL_LOGIN_FLOW_AUTH_EDIT_MODE_UPDATED,
    TARGET_SETTING_BASIC_AUTH_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_BASIC_AUTH_EDIT_MODE_UPDATED,
    TARGET_SETTING_HTTP_NTLM_AUTH_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_HTTP_NTLM_AUTH_EDIT_MODE_UPDATED,
    TARGET_SETTING_FORM_AUTH_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_FORM_AUTH_EDIT_MODE_UPDATED,
    TARGET_SETTING_JWT_AUTH_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_JWT_AUTH_EDIT_MODE_UPDATED,
    TARGET_SETTING_FETCH_DETAIL: ActionTypeList.TARGET_SETTING_DETAIL_FETCHED,
    ACCOUNT_TARGET_SETTING_UPDATE_CURRENT_DETAIL: ActionTypeList.ACCOUNT_TARGET_SETTING_CURRENT_DETAIL_UPDATED,
    TARGET_SETTING_TOGGLE_NEW_FLAG: ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED,
    TARGET_SETTING_UPDATE_FIELD: ActionTypeList.TARGET_SETTING_FIELD_UPDATED,
    TARGET_SETTING_UPDATE_CURRENT_TARGET_ID: ActionTypeList.TARGET_SETTING_CURRENT_TARGET_ID_UPDATED,
    TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE: ActionTypeList.TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED,
    ACCOUNT_TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE: ActionTypeList.ACCOUNT_TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED,
    TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST: ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_SUBMITTED,
    ACCOUNT_TARGET_SETTING_INITIATE_DEACTIVATION_MODE: ActionTypeList.ACCOUNT_TARGET_SETTING_DEACTIVATION_MODE_INITIATED,
    ACCOUNT_TARGET_SETTING_INITIATE_ACTIVATION_MODE: ActionTypeList.ACCOUNT_TARGET_SETTING_ACTIVATION_MODE_INITIATED,
    TARGET_SETTING_GET_AUTHENTICATION_TEST_RESULT: ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_RESULT_GOTTEN,
    TARGET_SETTING_SUBMIT_AUTHENTICATION_TEST_CANCELLATION: ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_CANCELLATION_SUBMITTED,
    TARGET_SETTING_RESET_AUTHENTICATION_TEST_RESULT: ActionTypeList.TARGET_SETTING_AUTHENTICATION_TEST_RESULT_RESET,
    TARGET_SETTING_UPDATE_SELECTED_AUTH_TAB_INDEX: ActionTypeList.TARGET_SETTING_SELECTED_AUTH_TAB_INDEX_UPDATED,

    /********************* Account ****************/
    ACCOUNT_SETTING_FETCH: ActionTypeList.ACCOUNT_SETTING_FETCHED,
    ACCOUNT_SETTING_UPDATE: ActionTypeList.ACCOUNT_SETTING_UPDATED,
    ACCOUNT_SETTING_UPDATE_FIELD: ActionTypeList.ACCOUNT_SETTING_FIELD_UPDATED,
    ACCOUNT_SETTING_UPDATE_TIME_ZONE_EDIT_MODE: ActionTypeList.ACCOUNT_SETTING_TIME_ZONE_EDIT_MODE_UPDATED,
    ACCOUNT_SETTING_UPDATE_GENERAL_EDIT_MODE: ActionTypeList.ACCOUNT_SETTING_GENERAL_EDIT_MODE_UPDATED,
    ACCOUNT_SETTING_UPDATE_BILLING_EDIT_MODE: ActionTypeList.ACCOUNT_SETTING_BILLING_EDIT_MODE_UPDATED,
    PUBLIC_PLANS_FETCH: ActionTypeList.PUBLIC_PLANS_FETCHED,
    ACCOUNT_SUBSCRIPTION_CANCEL: ActionTypeList.ACCOUNT_SUBSCRIPTION_CANCELLED,
    ACCOUNT_SUBSCRIPTION_UPDATE: ActionTypeList.ACCOUNT_SUBSCRIPTION_UPDATED,

    /********************* Dashboard ****************/
    DASHBOARD_DATA_FETCH: ActionTypeList.DASHBOARD_DATA_FETCHED,
    
    /********************* Scannings ****************/
    SCANNINGS_DATA_FETCH: ActionTypeList.SCANNINGS_DATA_FETCHED,
    SCAN_LAUNCH: ActionTypeList.SCAN_LAUNCHED,
    SCHEDULED_SCANS_TO_DB_SAVE: ActionTypeList.SCHEDULED_SCANS_TO_DB_SAVED,
    SCAN_CANCEL: ActionTypeList.SCAN_CANCELED,
    
    /********************* Findings ****************/
    FINDINGS_DATA_FETCH: ActionTypeList.FINDINGS_DATA_FETCHED,
    WIKI_DATA_FETCH: ActionTypeList.WIKI_DATA_FETCHED,

    /********************* Issue details ****************/
    ASSIGNEE_UPDATE: ActionTypeList.ASSIGNEE_UPDATED,
    FEEDBACK_UPDATE: ActionTypeList.FEEDBACK_UPDATED,

    /********************* Target details ****************/
    TARGET_DETAILS_DATA_FETCH: ActionTypeList.TARGET_DETAILS_DATA_FETCHED,

    /********************* Settings ****************/
    SETTINGS_DATA_FETCH: ActionTypeList.SETTINGS_DATA_FETCHED,

    /********************* Authentication ****************/
    AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER: ActionTypeList.AUTHENTICATION_CURRENT_LOGIN_USER_UPDATED,

    LOGIN_USER_GET: ActionTypeList.LOGIN_USER_GOTTEN,

    LOGIN_USER_UPDATE_FIELD: ActionTypeList.LOGIN_USER_FIELD_UPDATED,
    LOGIN_USER_UPDATE: ActionTypeList.LOGIN_USER_UPDATED,
    LOGIN_USER_UPDATE_EDIT_MODE: ActionTypeList.LOGIN_USER_EDIT_MODE_UPDATED,

    /********************* billing ****************/
    BILLING_DATA_FETCH: ActionTypeList.BILLING_DATA_FETCHED,

    /********************* notifications ****************/
    NOTIFICATIONS_DATA_FETCH: ActionTypeList.NOTIFICATIONS_DATA_FETCHED,
    NOTIFICATIONS_READ_STATUS_SAVE: ActionTypeList.NOTIFICATIONS_READ_STATUS_SAVED,
    NOTIFICATIONS_ACKNOWLEDGE_NEW_NOTIFICATIONS: ActionTypeList.NOTIFICATIONS_NEW_NOTIFICATIONS_ACKNOWLEDGED,

    /********************* Scan queue setting ****************/
    SCAN_QUEUE_SETTING_DATA_FETCH: ActionTypeList.SCAN_QUEUE_SETTING_DATA_FETCHED,
    SCAN_QUEUE_SETTING_UPDATE: ActionTypeList.SCAN_QUEUE_SETTING_UPDATED,
    SCAN_QUEUE_SETTING_NO_MSG_UPDATE: ActionTypeList.SCAN_QUEUE_SETTING_NO_MSG_UPDATED,

    /********************* Stripe ****************/
    CREATE_STRIPE_CHECKOUT_SESSION_REQUEST: ActionTypeList.CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS,

    /********************* Terms of Service ****************/
    TOS_LATEST_FETCH: ActionTypeList.TOS_LATEST_FETCHED,
    TOS_FETCH: ActionTypeList.TOS_FETCHED,
    TOS_ACCEPTANCE_SUBMIT: ActionTypeList.TOS_ACCEPTANCE_SUBMITTED,

    USER_ACCOUNT_ACTIVATION_FETCH: ActionTypeList.USER_ACCOUNT_ACTIVATION_FETCHED,

    LOGIN_PAGE_VISIT: ActionTypeList.LOGIN_PAGE_VISIT, // same action type
}
