import { ActionTypeList } from '../constants'
import { getPtApiUrl, getAxios, constructActionResponseWithGet } from '../../helpers'

export const fetchFindingsSummaryData = (accountId, isRefresh) => {
    const axios = getAxios();
    let action = 'findings-summary';
    let url = getPtApiUrl(accountId, action);

    if (isRefresh === true) {
        url += `?isRefresh=true`
    }
    return constructActionResponseWithGet(axios, url, ActionTypeList.FINDINGS_DATA_FETCH);
}

export const fetchFindingDetailsData = (accountId, setNumber) => {
    const axios = getAxios();
    let action = `finding-detail-sets/${setNumber}`;
    let url = getPtApiUrl(accountId, action);

    return constructActionResponseWithGet(axios, url, `${ActionTypeList.FINDINGS_DATA_FETCH}-${setNumber}`);
}