import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: null,
    error: "",
};

const scanQueueSettingUpdatePayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.SCAN_QUEUE_SETTING_UPDATED:
            return {
                loading: false,
                isSuccess: true,
                toShowMessage:true,
                message:"Queue settings saved successfuly.",
                error: ""
            };
        case ActionTypeList.SCAN_QUEUE_SETTING_UPDATE:
            console.error(`Server error: ${action.payload.data.error}.`);

            return {
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: 'Something went wrong, please try again later.'
            };
        case ActionTypeList.SCAN_QUEUE_SETTING_HIDE_MSG:
            return {
                loading: false,
                //isSuccess: true,
                toShowMessage:false,
            };
        case ActionTypeList.SCAN_QUEUE_SETTING_NO_MSG_UPDATED:
            return {
                loading: false,
                isSuccess: true,
                toShowMessage:false,
                error: ""
            };
        case ActionTypeList.SCAN_QUEUE_SETTING_NO_MSG_UPDATE: 
            console.error(`Server error: ${action.payload.data.error}.`);

            return {
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: 'Something went wrong, please try again later.'
            };
        default:
            return state;
    }
}

export default scanQueueSettingUpdatePayload;