import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    notificationsData: { SelectTop: 10, Items: null, HasUserAcknowledgedNewNotifications: true },
    error: "",
};

const NotificationsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.NOTIFICATIONS_DATA_FETCHED:
            return {
                loading: false,
                notificationsData: action.payload,
                error: ""
            };
        case ActionTypeList.NOTIFICATIONS_DATA_FETCH:
            return {
                loading: true,
                notificationsData: { SelectTop: 10, Items: null },
                error: action.payload
            };
        case ActionTypeList.NOTIFICATIONS_NEW_NOTIFICATIONS_ACKNOWLEDGED:
            return {
                loading: false,
                notificationsData: {
                    ...state.notificationsData,
                    HasUserAcknowledgedNewNotifications: true
                },
                error: ""
            };
        default:
            return state;
    }
}

export default NotificationsPayload;