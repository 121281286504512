import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: false,
    successMessage: '',
    toShowMessage: false,
    error: "",
};

const invitationDeliveryStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_SETTING_INVITATION_SENT:
            let isSuccess = true;
            let message = action.payload

            if (action.payload.error) {
                isSuccess = false;
                message = action.payload.message;
            }

            return {
                loading: false,
                isSuccess: isSuccess,
                successMessage: message,
                toShowMessage: true,
                error: ""
            };
        case ActionTypeList.USER_SETTING_SEND_INVITATION:
            return {
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: action.payload
            };
        case ActionTypeList.USER_SETTING_SEND_INVITATION_STATE_RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export default invitationDeliveryStatusPayload;