import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    saveScheduledScansToDBData: null,
    error: "",
    toShowMessage: false,
};

const saveScheduledScansToDBPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.SCHEDULED_SCANS_TO_DB_SAVED:
            return {
                loading: false,
                saveScheduledScansToDBData: action.payload,
                error: "",
                toShowMessage: false,
            };
        case ActionTypeList.SCHEDULED_SCANS_TO_DB_SAVE:
            return {
                loading: true,
                saveScheduledScansToDBData: null,
                error: action.payload,
                toShowMessage: true,
            };
        case ActionTypeList.SCHEDULED_SCANS_TO_DB_SAVE_HIDE_MSG:
            return {
                loading: false,
                toShowMessage: false,
            };

        default:
            return state;
    }
}

export default saveScheduledScansToDBPayload;