import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    isSuccess: false,
    error: "",
    successMessage: '',
    toShowMessage: false,
    toShowConfirmMessageBeforeAction: false,
};

const targetSettingUpdateStatusPayload = (state = initialState, action) => {

//    console.log(`${new Date()}: targetSettingUpdateStatusPayload action ${action.type} `);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_UPDATED:

            return {
                loading: false,
                isSuccess: true,
                error: "",
                successMessage: action.payload,
                toShowMessage: true,
                toShowConfirmMessageBeforeAction: false,
            };
        case ActionTypeList.TARGET_SETTING_UPDATE:
            return {
                loading: true,
                isSuccess: false,
                error: action.payload.data.error,
                toShowMessage: true,
                toShowConfirmMessageBeforeAction: false,
            };
        case ActionTypeList.TARGET_SETTING_EDIT_MODE_UPDATED:
        case ActionTypeList.TARGET_SETTING_UPDATE_STATUS_RESET:
            return initialState;
        default:
            return state;
    }
}

export default targetSettingUpdateStatusPayload;