import axios from 'axios'
import { ActionTypeEventMappingList } from '../redux/constants'
import { performAuthenticationCheck } from "../authentication/_helpers/index";
import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { InfiniteLoopTracker } from './InfiniteLoopTracker';
import { Internationalization } from '@syncfusion/ej2-base';

const _defaultDateTimeSecFormat = 'dd/MMM/yyyy HH:mm:ss (z)'
const _defaultDateTimeFormat = 'dd/MMM/yyyy HH:mm (z)'
const _defaultDateFormat = 'dd/MMM/yyyy'
const _defaultTimeFormat = 'hh:mm a'

const getTimeString = (value) => {
    const instance = new Internationalization();

    return instance.formatDate(value, { skeleton: 'hm' });
}

export const eventTemplate = (props) => {
    const primaryColor = { background: props.PrimaryColor };

    return (
        <div className="template-wrap" style={primaryColor}>
            <div className="subject" style={primaryColor}>{props.Subject}</div>
            <div className="time" style={primaryColor}>
                {getTimeString(props.StartTime)}</div>
        </div>
    );
}

export const getCurrentPage = () => {
    const path = window.location.pathname;

    const pathParts = path.split('/').filter(Boolean);

    if (pathParts.length === 0)
        return 'dashboard';

    return pathParts[pathParts.length - 1];
}

export const isString = (s) => {
    return (typeof s === 'string' || s instanceof String);
}

export function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export function formatPercentage(num) {
    if (!num)
        return '0%';

    return num.toFixed(2).padStart(5, '0') + '%';
}

export function getNotifsReadStatus(notifications) {
    let unsavedNotifs = [];

    if (!notifications)
        return unsavedNotifs;

    for (var i = 0; i < notifications.length; i++) {
        if (notifications[i].unsaved) {
            delete notifications[i].unsaved;
            delete notifications[i].isOpen;

            unsavedNotifs.push(notifications[i]);
        }
    }

    return unsavedNotifs;
}

export function isPathExist(pathname) {
    let paths = [
        "/",
        "/targets",
        "/target-details",
        "/scannings",
        "/findings",
        "/users",
        "/settings",
        "/scan-queue",
        "/scan-scheduler",
        "/tos-acceptance",
        "/tos",
        "/account-settings",
        "/billing",
        "/inbox",
        "/balance",
        "/profile",
        "/notifications",
        "/authentication/lockscreen",
        "/authentication/login",
        "/no-permission",
        "/not-found"
    ];

    if (paths.includes(pathname))
        return true;

    return false;
}
export function isUserAuthorized(permissions, pathname) {
    switch (pathname) {
        case "/": return permissions.Dashboard !== undefined;
        case "/targets":
        case "/target-details": return permissions.Targets !== undefined;
        case "/scannings": return permissions.Scannings !== undefined;
        case "/findings": return permissions.Findings !== undefined;
        case "/users": return permissions.UserList !== undefined;
        case "/settings": return permissions.ViewSettings !== undefined;
        case "/account-settings": return permissions.AccountSetting !== undefined;
        case "/billing": return permissions.AccountSetting !== undefined || permissions.ViewBilling;
        // case "/notifications": return permissions.AccountSetting !== undefined;

        default: return true;
    }
}
export * from "./messageBox";
export var ScanTypeList = { "None": -1, "SafeMode": 0, "Normale": 1, "OnePage": 2, }
export function disableButton(btnId, disable, onClick) {
    let btn = document.getElementById(btnId);
    if (!btn)
        return;

    if (disable) {
        btn.classList.add("disabled");
        btn.setAttribute("disabled", ""); // disabled = true;
        btn.onclick = {};
    }
    else {
        btn.classList.remove("disabled");
        btn.removeAttribute("disabled"); // disabled = false;
        btn.onclick = onClick;
    }
}
export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
export function saveToReduxStore(dispatch, type, payload) {
    dispatch({ type: type, payload: payload })
}
export function formatDate(value) {
    let date = new Date(value);

    if (isValidDate(date)) {
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '/' + month + '/' + year;
    } else {
        console.log(`${new Date()}: formatDate '${JSON.stringify(value)}'. Invalid date`);

        return '';
    }
}

export function isNullOrEmpty(str) {
    return str === null || str === undefined || str === '';
}

function isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime());
}

export function setHtmlPageTitle(titlePart) {
    let title = `${titlePart} - web-scanner.app`;
    document.title = title;
    window.top.document.title = title;
}

export function getSortOrderList() {
    let result = new Map();

    result.set("None", -1);
    result.set("Ascending", 0);
    result.set("Descending", 1);

    return result;
}

export function getSeverity(severity) {
    //var severities = ['HIGH', 'MEDIUM', 'LOW'];
    //let i = parseInt(severity);
    //if (i === NaN)
    //    return null;

    //return severities[i]
    return severity;
}
export function getSeverityColor(severity, palettes) {
    if (!palettes)
        return null;

    let i = 0;
    switch (severity) {
        case "Low": i = 0; break;
        case "Medium": i = 1; break;
        case "High": i = 2; break;
        default: return null;
    }

    return palettes[i]
}
export function getReactDivElement(htmlStr) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
    );
}

export const API_ROOT = () => {
    return getApiRoot();
}

export const getUserApiUrl = (accountId, userId, action) => {

    const apiRoot = getApiRoot();

    return `${apiRoot}/accounts/${accountId}/users/${userId}/${action}`;
}

export const getPtApiUrl = (accountId, action) => {

    const apiRoot = getApiRoot();

    return `${apiRoot}/accounts/${accountId}/pt/${action}`;
}

export function getApiRoot() {
    let hostname = window && window.location && window.location.hostname;

    if (hostname.includes("localhost"))
        return process.env.REACT_APP_LOCAL_UI_API_URL;

    return process.env.REACT_APP_GATEWAY_API_URL;
}

export function getSalesApiRoot() {

    return process.env.REACT_APP_SALES_API_URL;
}

export function getTosRootUrl() {

    return process.env.REACT_APP_TOS_HOST_URL + '/terms-of-service';
}

export function getSalesHostUrl() {

    return process.env.REACT_APP_SALES_HOST_URL;
}

export const getAxios = (name) => {
    switch (name) {
        case '':
        case undefined:
            let axiosInst = axios.create({});
            return axiosInst;
        default:
            return null;
    }
}

export const createDivElementFromHTML = (htmlString) => {
    var div = document.createElement('div');
    div.innerHTML = '<div>' + htmlString.trim() + '</div>';

    return div;
}

const getWikiObjectFromHtml = (markup) => {
    var doc = (new DOMParser()).parseFromString(markup, "text/html");
    var mainContents = doc.querySelectorAll("#main-content");

    let wikiObject = [];
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Information</div>"),
        content: mainContents[0]
    });
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Background</div>"),
        content: mainContents[1]
    });
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Classification</div>"),
        content: mainContents[2]
    });
    wikiObject.push({
        header: createDivElementFromHTML("<div style='font-size: 1.1rem'>Read more</div>"),
        content: mainContents[3]
    });

    return wikiObject;
}

export const getWikiObject = (axios, url, actionType) => async (dispatch) => {
    const config = {
        headers: {
            'accept': '*/*',
            'content-type': 'text/plain',
        }
    };

    const mappedActionType = getMappedActionType(actionType);

    await axios
        .get(url, config)
        .then((response) => {
            if (response.status !== 200) {
                return dispatch(constructActionErrorResponse(mappedActionType, response.statusText))
            }

            let markup = response.data;
            if (!markup) {
                return dispatch(constructActionErrorResponse(mappedActionType, "No html page was returned from server."));
            }

            let wikiObject = getWikiObjectFromHtml(markup);
            return dispatch({
                type: mappedActionType,
                payload: wikiObject,
            });
        })
        .catch((error) => {
            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });

}

const getConfig = () => {
    const token = 'd6ac2fe46f34f522fbb55ee6c229c1367b2431be69ededa096c0909e1adef0d8';

    return {
        headers: { "Authorization": `Bearer ${token}` }
    };
}

const checkInfiniteLoop = () => {
    const tracker = new InfiniteLoopTracker();

    return (tracker.check());
}

export const startInfiniteLoopTracker = () => {
    const tracker = new InfiniteLoopTracker();

    tracker.start();
}

export const constructActionResponse = (axios, url, actionType) => async (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(mappedActionType, "Infinite http loop detected."));
    }

    await axios
        .get(url, getConfig)
        .then((response) => {
            if (response.status !== 200) {
                return dispatch(constructActionErrorResponse(mappedActionType, response.statusText))
            } else if (response.data.status !== 200) {
                return dispatch(constructActionErrorResponse(mappedActionType, response.data.statusText))
            } else {
                return dispatch({
                    type: mappedActionType,
                    payload: response.data.data
                })
            }
        })
        .catch((error) => {
            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithMock = (mockData, actionType) => async (dispatch) => {
    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(actionType, "infinite http loop detected."));
    }

    let response = {
        status: 200,
        data: mockData
    }

    return dispatch(genericConstructActionResponse(response, actionType));
}

export const constructActionResponseWithGet = (axios, url, actionType) => async (dispatch) => {
    const config = {
        headers: {
            'accept': '*/*',
            'content-type': 'text/plain',
        }
    };

    console.log(`${new Date()}: helpers::constructActionResponseWithGet await axios.get(${url}, config) starts`);

    const mappedActionType = getMappedActionType(actionType);

    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(actionType, "infinite http loop detected."));
    }

    await axios
        .get(url, config)
        .then((response) => {

            performAuthenticationCheck(response);

            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`${new Date()}: helpers::constructActionResponseWithGet error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithPost = (axios, url, data, actionType, onSuccessCallBack) => async (dispatch) => {
    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(actionType, "infinite http loop detected."));
    }

    if (data.throttled) {
        return dispatch(constructActionErrorResponse(actionType, "Request throttled."));
    }

    await axios
        .post(url, data)
        .then((response) => {

            console.log(`${new Date()}: helpers::constructActionResponseWithPost response ${JSON.stringify(response)}`)

            performAuthenticationCheck(response);

            if (onSuccessCallBack !== undefined) {
                onSuccessCallBack(dispatch);
            }

            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`${new Date()}: helpers::constructActionResponseWithPost constructActionResponseWithPost ` +
                `error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(actionType, error));
        });
}

export const constructActionResponseWithPut = (axios, url, data, actionType) => async (dispatch) => {
    console.log(`${new Date()}: helpers::constructActionResponseWithPut action ${actionType} await axios.put(${url}, ${JSON.stringify(data)}) starts`);

    let mappedActionType = getMappedActionType(actionType);

    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(actionType, "infinite http loop detected."));
    }

    await axios
        .put(url, data)
        .then((response) => {
            performAuthenticationCheck(response);

            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`${new Date()}: helpers::constructActionResponseWithPut constructActionResponseWithPut error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(actionType, error))
        });
}

export const constructActionResponseWithFormDataPut = (axios, url, formData, fileType, actionType) => async (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(actionType, "infinite http loop detected."));
    }

    await axios
        .put(url, formData, {
            headers: {
                'Content-Type': fileType
            }
        })
        .then((response) => {
            return dispatch(genericConstructActionResponse(response, actionType));
        })
        .catch((error) => {
            console.log(`${new Date()}: helpers::constructActionResponseWithFormDataPut constructActionResponseWithPut error response from ${url}: ${JSON.stringify(error)}`);

            return dispatch(constructActionErrorResponse(mappedActionType, error))
        });
}

export const constructActionResponseWithDelete = (axios, url, data, actionType) => async (dispatch) => {
    if (checkInfiniteLoop()) {
        return dispatch(constructActionErrorResponse(actionType, "infinite http loop detected."));
    }

    await axios.delete(url, {
        data
    }).then((response) => {
        return dispatch(genericConstructActionResponse(response, actionType));
    }).catch((error) => {
        console.log(`${new Date()}: helpers::constructActionResponseWithDelete error response from ${url}: ${JSON.stringify(error)}`);

        return dispatch(constructActionErrorResponse(actionType, error))
    });
}

export const constructActionResponseWithMockData = (mockData, actionType) => async (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    return dispatch({
        type: mappedActionType,
        payload: mockData.data
    })
}

export const constructActionResponseWithInput = (input, actionType) => async (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    return dispatch({
        type: mappedActionType,
        payload: input
    })
}

export const constructActionSuccessResponse = (actionType) => (dispatch) => {
    const mappedActionType = getMappedActionType(actionType);

    return dispatch({
        type: mappedActionType,
        payload: true
    })
}

export const formatUnixTimeToString = (milliseconds, timeZone, pattern) => {
    if (milliseconds === undefined ||
        milliseconds === null ||
        milliseconds === 0) {
        return '';
    }

    return formatInTimeZone(milliseconds, timeZone, pattern);
}

export const genericConstructActionResponse = (response, actionType) => {
    console.log(`${new Date()}: genericConstructActionResponse response content length ${JSON.stringify(response)?.length} actionType ${actionType}`);

    const mappedActionType = getMappedActionType(actionType);

    if (response.status !== 200 && response.status !== 202) {
        return constructActionErrorResponse(actionType, response.statusText);
    } else if (response.data.status !== undefined && response.data.status !== 200 && response.data.status !== 202) {
        return constructActionErrorResponse(actionType, response.data.statusText);
    } else {
        if (response.data !== undefined && response.data.status !== undefined) {
            return {
                type: mappedActionType,
                payload: response.data.data,
                isResponseOk: true
            };
        } else {
            return {
                type: mappedActionType,
                payload: response.data,
                isResponseOk: true
            };
        }
    }
}

const getMappedActionType = (actionType) => {
    let mappedActionType = '';

    if (ActionTypeEventMappingList[actionType] === undefined) {
        mappedActionType = 'Done-' + actionType;
    } else {
        mappedActionType = ActionTypeEventMappingList[actionType];
    }

    return mappedActionType;
}

export const constructActionErrorResponse = (actionType, error) => {
    if (error !== undefined &&
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.error !== undefined) {

        return {
            type: actionType,
            payload: {
                data: {
                    error: error.response.data.error,
                    hasError: true
                }
            }
        }

    } else {
        return {
            type: actionType,
            payload: {
                data: {
                    error: error,
                    hasError: true
                }
            }
        }
    }
}

export const getUserDefinedDateTimeString = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false &&
                timeValue.includes("+") === false &&
                timeValue.includes("-") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) === false) {

            const result = format(utcToZonedTime(date, timeZone),
                _defaultDateTimeFormat, { timeZone });

            return result;
        }
    }

    return '';
}

export const getUserDefinedDateTimeSecString = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false &&
                timeValue.includes("+") === false &&
                timeValue.includes("-") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) === false) {

            const result = format(utcToZonedTime(date, timeZone),
                _defaultDateTimeSecFormat, { timeZone });

            return result;
        }
    }

    return '';
}

export const getUserDefinedDateString = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false &&
                timeValue.includes("+") === false &&
                timeValue.includes("-") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) === false) {

            const result = format(utcToZonedTime(date, timeZone),
                _defaultDateFormat, { timeZone });

            return result;
        }
    }

    return '';
}

export const getUserDefinedTimeString = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false &&
                timeValue.includes("+") === false &&
                timeValue.includes("-") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) === false) {

            const result = format(utcToZonedTime(date, timeZone),
                _defaultTimeFormat, { timeZone });

            console.log(`${new Date()}: helpers::getUserDefinedTimeString(${timeValue},${timeZone}) = ${result}`);

            return result;
        }
    }

    return '';
}

export const getUserDefinedTimeZonedDate = (timeValue, timeZone) => {

    if (timeValue !== undefined && timeValue != null) {
        let date = null;

        if (typeof timeValue === 'string') {
            if (timeValue.includes("Z") === false) {
                timeValue += 'Z';
            }

            date = new Date(timeValue);
        } else {
            date = timeValue;
        }

        if ((date.getFullYear() === 1 &&
            date.getMonth() === 0 &&
            date.getDay() === 1) == false) {

            const result = utcToZonedTime(date, timeZone);

            return result;
        }
    }

    return timeValue;
}

export const constructNewAction = (action, payload) => {
    return {
        type: action,
        payload
    };
}
